<template>
  <v-dialog v-model="dialog" persistent max-width="70%" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">
          Vincular Seção - Equipe: {{ dadosEquipe.nome_equipe }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <h4 class="mb-2">
              <v-icon class="mr-1" color="red">mdi-close</v-icon>Seções não
              Vinculadas
            </h4>
            <v-btn
              class="mb-3"
              color="success"
              @click="addTodasSecoes()"
              :disabled="secoesNaoVinculadas.length == 0"
            >
              Adicionar todas
            </v-btn>
            <draggable
              handle=".handle"
              class="draggable-area exclude-companies"
              :list="secoesNaoVinculadas"
              group="equipes"
              @change="moveAdded()"
            >
              <div
                class="v-list-item__content list"
                v-for="secao in secoesNaoVinculadas"
                :key="secao.cod_secao"
              >
                <div class="list-equipe-items">
                  <div class="list-equipe">
                    <v-row class="ml-0">
                      <strong class="mr-1">{{ `${secao.cod_secao} -` }}</strong>
                      {{ secao.secao }}
                    </v-row>
                    <v-row class="ml-0">
                      <strong class="mr-1">{{
                        `${secao.cod_funcao} -`
                      }}</strong>
                      {{ secao.funcao }}
                    </v-row>
                  </div>
                  <div class="mr-4">
                    <v-btn icon @click="addSecao(secao)">
                      <v-icon color="green">mdi-arrow-right-bold</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </draggable>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col>
            <h4 class="mb-2">
              <v-icon class="mr-1" color="green">mdi-check</v-icon>Seções
              Vinculadas
            </h4>
            <v-btn
              class="mb-3"
              color="error"
              @click="removerTodasSecoes()"
              :disabled="secoesVinculadas.length == 0"
            >
              Remover todas
            </v-btn>
            <draggable
              handle=".handle"
              class="draggable-area include-companies"
              :list="secoesVinculadas"
              group="equipes"
              @change="moveRemove()"
            >
              <div
                class="v-list-item__content"
                v-for="secao in secoesVinculadas"
                :key="secao.id_equipe_secao"
              >
                <div class="list-equipe-participantes-items">
                  <div>
                    <v-btn class="mr-4" icon @click="removeSecao(secao)">
                      <v-icon color="red">mdi-arrow-left-bold</v-icon>
                    </v-btn>
                  </div>
                  <div class="list-equipe mr-4">
                    <v-row class="ml-0">
                      <strong class="mr-1">{{ `${secao.cod_secao} -` }}</strong>

                      {{ secao.secao }}
                    </v-row>
                    <v-row class="ml-0">
                      <strong class="mr-1">{{
                        `${secao.cod_funcao} -`
                      }}</strong>

                      {{ secao.funcao }}
                    </v-row>
                  </div>
                </div>
              </div>
            </draggable>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close()">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import draggable from "vuedraggable";
import comissao from "@/services/http/comissaoService";

export default {
  name: "DraggableEquipesCampanha",

  components: {
    draggable
  },

  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    dadosEquipe: {
      type: Object
    },

    secoesNaoVinculadas: {
      type: Array
    },

    secoesVinculadas: {
      type: Array
    }
  },

  data() {
    return {
      // secoesNaoVinculadas: [],
      // secoesVinculadas: []
    };
  },

  watch: {
    dialog(value) {
      return value;
    },

    secoesNaoVinculadas(value) {
      return value;
    },

    secoesVinculadas(value) {
      return value;
    },

    dadosEquipe(value) {
      return value;
    }
  },

  methods: {
    async addTodasSecoes() {
      try {
        let allPost = this.secoesNaoVinculadas.map(item => {
          const form = {
            id_equipe: this.dadosEquipe.id_equipe,
            id_empresa: item.id_empresa,
            cod_secao: item.cod_secao,
            ativo: "S",
            id_funcao: item.id_funcao
          };

          return comissao()
            .equipeSecao()
            .store(form, {
              notification: true,
              message: "Seção vinculada com sucesso!"
            });
        });

        await Promise.all(allPost);
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "error",
          text: `Erro ao adicionar equipe`
        });
      } finally {
        this.$emit("change:fecthVinculaSecao", this.dadosEquipe);
      }
    },
    async removerTodasSecoes() {
      try {
        let allDelete = this.secoesVinculadas.map(item => {
          return comissao()
            .equipeSecao(item.id_equipe_secao)
            .delete(
              {},
              { notification: true, message: "Seção removida com sucesso!" }
            );
        });
        await Promise.all(allDelete);
      } catch (error) {
        this.$notify({
          type: "error",
          text: `${error.response.data.message}` || `Erro ao remover equipes`
        });
      } finally {
        this.$emit("change:fecthVinculaSecao", this.dadosEquipe);
      }
    },

    async addSecao(item) {
      try {
        const form = {
          id_equipe: this.dadosEquipe.id_equipe,
          id_empresa: item.id_empresa,
          cod_secao: item.cod_secao,
          ativo: "S",
          id_funcao: item.id_funcao
        };
        await comissao()
          .equipeSecao()
          .store(form, {
            notification: true,
            message: "Seção vinculada com sucesso!"
          });
      } catch (error) {
        this.$notify({
          type: "error",
          text: ` Erro ao adicionar  ${this.dadosEquipe.nome_equipe}`
        });
      } finally {
        this.$emit("change:fecthVinculaSecao", this.dadosEquipe);
      }
    },

    async removeSecao(item) {
      try {
        await comissao()
          .equipeSecao(item.id_equipe_secao)
          .delete(
            {},
            { notification: true, message: "Seção removida com sucesso!" }
          );
      } catch (error) {
        this.$notify({
          type: "error",
          text: `${error.response.data.message}` || `Erro ao remover equipe`
        });
      } finally {
        this.$emit("change:fecthVinculaSecao", this.dadosEquipe);
      }
    },

    moveAdded(evt) {
      let secao = {};
      const { removed } = evt;

      if (removed) {
        secao = removed.element;
        this.addSecao(secao);
      }
    },

    moveRemove(evt) {
      let secao = {};
      const { removed } = evt;

      if (removed) {
        secao = removed.element;
        this.removeSecao(secao);
      }
    },

    close() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.v-list-item__content {
  padding-inline-start: 15px;
  border: 1px solid hsla(0, 0%, 31%, 0.199);
  border-radius: 10px;
  margin-bottom: 4px;
}

.draggable-area {
  min-height: 300px;
  padding: 8px;
}

.draggable-area.include-companies {
  border: 2px dashed hsla(128, 100%, 34%, 0.39);
}

.draggable-area.exclude-companies {
  border: 2px dashed hsla(347, 100%, 34%, 0.39);
}
.v-list-item__content .list {
  display: flex !important;
  justify-content: space-between;
}

.list-equipe-items {
  display: flex !important;
  justify-content: space-between;
}

.list-equipe-participantes-items {
  display: flex !important;
}

.list-equipe {
  display: flex !important;
  flex-direction: column;
}
</style>
